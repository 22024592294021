<template>
  <b-modal
    id="edit-vaccine-sidebar"
    centered
    :visible="isEditVaccineSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="lg"
    backdrop
    no-header
    right
    :title="$t('updateVaccineVaccine')"
    :ok-title="$t('ok')"
    ok-only
    @ok="onSubmit(blankVaccineData)"
    @hidden="formValidation(resetblankvaccine).resetForm"
    @change="(val) => changed(val)"
  >
    <!-- Header -->

    <!-- <b-media no-body class="p-2">
        <b-media-aside>
          <b-link>
            <b-img
              ref="previewEl"
              rounded
              :src="
                'https://api.herdoncloud.com/Vaccine_Pictures/' +
                blankVaccineData.pictureurl
              "
              height="100"
            />
          </b-link>
          avatar
        </b-media-aside>
        <b-media-body class="mt-75 ml-75">
         upload button
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            {{ $t("upload") }}
          </b-button>
          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @input="inputImageRenderer"
          />
     

        
          <b-card-text></b-card-text>
        </b-media-body>
      </b-media> -->
    <!--/ media -->
    <!-- BODY -->

    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblankvaccine
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 modal-form"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <button ref="submitButtonRef" type="submit" class="d-none" />
        <!-- vaccineName -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="vaccineName"
        >
          <b-form-group
            :label="$t('vaccineVaccineName')"
            label-for="vaccineName"
          >
            <b-form-input
              id="evaccineName"
              v-model="blankVaccineData.vaccineName"
              autofocus
              :state="
                formValidation(resetblankvaccine).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- daystoeffectmilk -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="daystoeffectmilk"
        >
          <b-form-group
            :label="$t('daystoeffectmilk')"
            label-for="daystoeffectmilk"
          >
            <b-form-input
              id="edaystoeffectmilk"
              v-model="blankVaccineData.daystoeffectmilk"
              autofocus
              :state="
                formValidation(resetblankvaccine).getValidationState(
                  validationContext
                )
              "
              trim
              type="number"
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <!-- <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("update") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div> -->
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditVaccineSidebarActive",
    event: "update:is-edit-vaccine-sidebar-active",
  },
  props: {
    isEditVaccineSidebarActive: {
      type: Boolean,
      required: true,
    },
    vaccine: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankVaccineData: { ...this.vaccine },
      formValidation: formValidation,
    };
  },
  mounted() {},
  methods: {
    async changed(val) {
      this.resetblankvaccine();

      this.$emit("update:is-edit-vaccine-sidebar-active", val);
    },
    onSubmit() {
      store
        .dispatch("definitionsModule/updateVaccine", {
          stockamount: this.blankVaccineData.stokmiktari,
          ...this.blankVaccineData,
        })
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-vaccine-sidebar-active", false);
        });
    },
    async resetblankvaccine() {
      this.blankVaccineData = {
        ...this.vaccine,
        vaccineid: this.vaccine.id,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-vaccine-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
