<template>
  <b-modal
    id="add-new-vaccine-stock-sidebar"
    centered
    :visible="isEditVaccineStockSidebarActive"
    shadow
    size="lg"
    backdrop
    no-header
    :title="$t('vaccineVaccineStockEdit')"
    right
    ok-only
    :ok-title="$t('ok')"
    @ok="$refs.submitButtonRef.click()"
    @hidden="formValidation(resetblankvaccine).resetForm"
    @change="(val) => changed(val)"
  >
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblankvaccine
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 modal-form"
        @submit.prevent="handleSubmit(onSubmit(blankVaccineData))"
        @reset.prevent="resetForm"
      >
        <button ref="submitButtonRef" type="submit" class="d-none" />
        <!-- priceperVaccine -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="priceperVaccine"
        >
          <b-form-group
            :label="$t('priceperVaccine')"
            label-for="priceperVaccine"
          >
            <b-form-input
              id="priceperVaccine"
              v-model="blankVaccineData.priceperVaccine"
              autofocus
              :state="
                formValidation(resetblankvaccine).getValidationState(
                  validationContext
                )
              "
              type="number"
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- purchaseamount -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="purchaseamount"
        >
          <b-form-group
            :label="$t('purchaseamount')"
            label-for="purchaseamount"
          >
            <b-form-input
              id="purchaseamount"
              v-model="blankVaccineData.purchaseamount"
              autofocus
              :state="
                formValidation(resetblankvaccine).getValidationState(
                  validationContext
                )
              "
              trim
              type="number"
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- purchasedate -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="purchasedate"
        >
          <b-form-group :label="$t('purchasedate')" label-for="purchasedate">
            <b-form-datepicker
              id="purchasedate"
              v-model="blankVaccineData.purchasedate"
              :state="
                formValidation(resetblankvaccine).getValidationState(
                  validationContext
                )
              "
              :locale="$i18n.locale"
              :placeholder="$t('purchasedate')"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <div>
          <span>
            {{ $t("totalPrice") }}
            {{
              parseFloat(
                blankVaccineData.priceperVaccine *
                  blankVaccineData.purchaseamount
              ).toFixed(getUserData().pricedecimal)
            }}
            {{ getUserData().currency }}
          </span>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { getUserData } from "@/auth/utils";
import dayjs from "dayjs";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditVaccineStockSidebarActive",
    event: "update:is-edit-vaccine-stock-sidebar-active",
  },
  props: {
    isEditVaccineStockSidebarActive: {
      type: Boolean,
      required: true,
    },

    purchase: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dayjs,
      required,
      blankVaccineData: {
        id: 0,
        priceperVaccine: 0,
        purchaseamount: 0,
        purchasedate: dayjs(Date.now()).format("YYYY-MM-DD"),
        stockleft: 0,
        companyid: parseInt(getUserData().companyid),
        plantid: parseInt(this.$store.state.app.selectedPlantId),
        vaccineid: this.vaccineId,
      },
      formValidation: formValidation,
    };
  },
  computed: {
    getUserData() {
      return getUserData;
    },
  },

  async mounted() {
    this.resetblankvaccine();
  },
  methods: {
    async changed(val) {
      this.resetblankvaccine();

      this.$emit("update:is-edit-vaccine-stock-sidebar-active", val);
    },
    onSubmit() {
      store
        .dispatch("definitionsModule/updateVaccinePurchase", {
          stockamount: this.blankVaccineData.stokmiktari,
          ...this.blankVaccineData,
        })
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-vaccine-stock-sidebar-active", false);
        });
    },
    async resetblankvaccine() {
      this.blankVaccineData = {
        ...this.purchase,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-vaccine-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
