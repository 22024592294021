<template>
  <b-modal
    id="medicine-stock-sidebar"
    :visible="isMedicineStockSidebarActive"
    :title="$t('medicineStock') + ' ' + medicineName"
    bg-variant="white"
    shadow
    size="lg"
    centered
    backdrop
    no-header
    :ok-title="$t('ok')"
    ok-only
    @ok="$emit('refetch-data')"
    @hidden="formValidation(resetblankmedicine).resetForm"
    @change="(val) => changed(val)"
  >
    <medicine-add-stock
      key="1"
      :is-add-new-medicine-stock-sidebar-active.sync="
        isAddNewMedicineStockSidebarActive
      "
      :medicine-id="medicine.id"
      @refetch-data="getPurchases()"
    />
    <medicine-edit-stock
      :key="'purchase' + JSON.stringify(selectedPurchase)"
      :is-edit-medicine-stock-sidebar-active.sync="
        isEditMedicineStockSidebarActive
      "
      :purchase="selectedPurchase"
      @refetch-data="getPurchases()"
    />
    <div class="d-flex">
      <!-- price card -->
      <div class="card mr-2 border">
        <div class="card-body">
          <h5 class="card-title">
            {{ $t("totalPrice") }}
          </h5>
          <p
            class="card-text mx-auto d-flex align-items-center justify-content-center font-weight-bold h5"
          >
            {{ totalCost.toFixed(getUserData.pricedecimal) }}
            {{ getUserData.currency }}
          </p>
        </div>
      </div>
      <div class="card mr-2 border">
        <div class="card-body">
          <h5 class="card-title">
            {{ $t("totalStock") }}
          </h5>
          <p
            class="card-text mx-auto d-flex align-items-center justify-content-center font-weight-bold h5"
          >
            {{ totalStock }} {{ $t("piece") }}
          </p>
        </div>
      </div>
      <div class="card mr-2 border">
        <div class="card-body">
          <h5 class="card-title">
            {{ $t("avarageCost") }}
          </h5>
          <p
            class="card-text mx-auto d-flex align-items-center justify-content-center font-weight-bold h5"
          >
            {{ avarageCost.toFixed(getUserData.pricedecimal) }}
            {{ getUserData.currency }}
          </p>
        </div>
      </div>
    </div>
    <div class="mb-2 d-flex justify-content-between">
      <div class="w-50">
        <b-form-input
          id="searchQuery"
          v-model="searchQuery"
          class="mr-1 mt-1"
          :placeholder="$t('search')"
        />
      </div>
      <b-button
        class="mt-1"
        variant="primary"
        @click="
          isAddNewMedicineStockSidebarActive =
            !isAddNewMedicineStockSidebarActive
        "
      >
        <span class="text-nowrap">{{ $t("newMedicineStock") }}</span>
      </b-button>
    </div>
    <b-table
      ref="refMedicineListTable"
      class="position-relative"
      size="sm"
      small
      :items="purchases"
      responsive
      :fields="[
        { key: 'pricepermedicine', label: $t('pricepermedicine') },
        { key: 'purchaseamount', label: $t('purchaseamount') },
        {
          key: 'purchasedate',
          label: $t('purchasedate'),
          formatter: (val) =>
            `${dayjs(val)
              .locale(`${$i18n.locale}`)
              .format('DD MMMM YYYY dddd')}`,
        },
        { key: 'stockleft', label: $t('stockleft') },
        { key: 'actions', label: $t('actions') },
      ]"
      primary-key="id"
      show-empty
      :empty-text="$t('noRecords')"
      :per-page="perPage"
      :current-page="currentPage"
      pagination-simple
    >
      <template #cell(actions)="data">
        <b-button size="sm" variant="primary" @click="editStock(data.item)">
          <span class="text-nowrap">{{ $t("edit") }}</span>
        </b-button>

        <b-button
          class="ml-1"
          size="sm"
          variant="danger"
          @click="removePurchase(data.item.id)"
        >
          <span class="text-nowrap">{{ $t("delete") }}</span>
        </b-button>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col />
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="purchases.length"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BTable,
  BButton,
  BRow,
  BCol,
  BPagination,
  BFormInput,
} from "bootstrap-vue";

import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import { getUserData } from "@/auth/utils";
import MedicineAddStock from "./MedicineAddStock.vue";
import MedicineEditStock from "./MedicineEditStock.vue";
import dayjs from "dayjs";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
export default {
  components: {
    BModal,
    BTable,
    BButton,
    BRow,
    BCol,
    BPagination,
    MedicineAddStock,
    MedicineEditStock,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isMedicineStockSidebarActive",
    event: "update:is-medicine-stock-sidebar-active",
  },
  props: {
    isMedicineStockSidebarActive: {
      type: Boolean,
      required: true,
    },
    medicine: {
      type: Object,
      required: true,
    },
    purchasesFunction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dayjs,
      required,
      formValidation: formValidation,
      stocks: [],
      isEditMedicineStockSidebarActive: false,
      perPage: 10,
      currentPage: 1,
      isAddNewMedicineStockSidebarActive: false,
      purchases: [],
      searchQuery: "",
      selectedPurchase: {},
      getUserData: getUserData(),
    };
  },
  computed: {
    medicineName() {
      return this.medicine.medicineName;
    },
    totalStock() {
      return this.purchases.reduce((a, b) => a + b.stockleft, 0) ?? 0;
    },
    totalCost() {
      return (
        this.purchases.reduce(
          (a, b) => a + b.pricepermedicine * b.purchaseamount,
          0
        ) ?? 0
      );
    },
    avarageCost() {
      var avarageCost = this.totalCost / this.totalStock;
      return isNaN(avarageCost) || avarageCost == Infinity ? 0 : avarageCost;
    },
  },

  async mounted() {
    this.getPurchases();
  },
  methods: {
    async changed(val) {
      this.$emit("update:is-medicine-stock-sidebar-active", val);
      this.resetblankmedicine();
    },
    async getPurchases() {
      if (this.medicine.id)
        this.purchases = await this.purchasesFunction(this.medicine.id);
    },
    async editStock(stock) {
      this.isEditMedicineStockSidebarActive = true;
      this.selectedPurchase = stock;
    },

    async resetblankmedicine() {
      this.blankMedicineData = {
        id: 0,
        medicinename: "",
        stokmiktari: 0,
        daystoeffectmilk: 0,
        birimfiyat: 0,
        companyid: parseInt(getUserData().companyid),
        plantid: parseInt(this.$store.state.app.selectedPlantId),
      };
    },
    async removePurchase(id) {
      this.$store
        .dispatch("definitionsModule/deleteMedicinePurchase", id)
        .then((response) => {
          this.getPurchases();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("successful"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$t("deleted", { type: this.$t("medicine") }),
            },
          });
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("unsuccessful"),
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: this.$t("notDeleted", { type: this.$t("medicine") }),
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#Medicine-new-medicine-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
