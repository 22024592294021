<template>
  <div>
    <b-card no-body class="mb-0">
      <b-tabs class="mt-2 ml-2" content-class="ml-n2" active pills>
        <b-tab :title="$t('medicine')"><medicine-list /></b-tab>
        <b-tab :title="$t('vaccine')"><vaccine-list /></b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BCard, BTabs, BTab } from "bootstrap-vue";
import MedicineList from "./components/MedicineList/MedicineList.vue";
import VaccineList from "./components/VaccineList/VaccineList.vue";
export default {
  components: { BCard, MedicineList, BTabs, BTab, VaccineList },
};
</script>

<style></style>
