<template>
  <b-modal
    id="add-new-medicine-sidebar"
    :visible="isAddPartnerSidebarActive"
    :title="$t('partnersMedicines')"
    bg-variant="white"
    shadow
    size="lg"
    backdrop
    no-header
    centered
    :ok-title="$t('ok')"
    ok-only
    @ok="$refs.submitButtonRef.click()"
    @hidden="formValidation(resetblankmedicine).resetForm"
    @change="(val) => changed(val)"
  >
    <medicine-list-add
      :is-add-new-medicine-sidebar-active.sync="isAddNewMedicineSidebarActive"
      @refetch-data="$emit('refetch-data')"
    />
    <div class="d-flex justify-content-between">
      <div class="w-75 d-flex mr-2">
        <b-form-input
          id="searchQuery"
          v-model="searchQuery"
          class="mr-1 mt-1 w-50"
          :placeholder="$t('search')"
        />
        <v-select
          v-model="owner"
          :options="ownerOptions"
          :reduce="(owner) => owner"
          :placeholder="$t('owner')"
          class="mt-1 w-50"
        >
          <template #selected-option="data">
            {{ data.label.substring(0, 20) + "..." }}
          </template>
        </v-select>
      </div>
    </div>
    <div class="mb-2 d-flex justify-content-between">
      <div class="w-75 d-flex mr-2">
        <v-select
          v-model="farmosetik"
          :options="farmosetikOptions"
          :reduce="(farmosetik) => farmosetik"
          :placeholder="$t('farmosetik')"
          class="mt-1 w-50"
        >
        </v-select>
        <v-select
          v-model="application"
          :options="applicationOptions"
          :reduce="(application) => application"
          :placeholder="$t('application')"
          class="mt-1 ml-1 w-50"
        >
        </v-select>
      </div>
      <b-button
        class="mt-1"
        variant="primary"
        @click="isAddNewMedicineSidebarActive = !isAddNewMedicineSidebarActive"
      >
        <span class="text-nowrap">{{ $t("newMedicine") }}</span>
      </b-button>
    </div>
    <b-table
      ref="refMedicineListTable"
      class="position-relative"
      size="sm"
      small
      :items="filteredMedicines"
      responsive
      :fields="[
        { key: 'medicineName', label: $t('medicineName') },
        { key: 'application', label: $t('application') },
        { key: 'daystoeffectmilk', label: $t('daystoeffectmilk') },
        {
          key: 'farmosetik',
          label: $t('farmosetik'),
          formatter: (val) => val.charAt(0).toUpperCase() + val.slice(1),
        },
        {
          key: 'owner',
          label: $t('owner'),
        },

        { key: 'actions', label: $t('actions') },
      ]"
      primary-key="id"
      show-empty
      :empty-text="$t('noRecords')"
      :per-page="perPage"
      :current-page="currentPage"
      pagination-simple
    >
      <template #cell(actions)="data">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="addPartner(data.item)"
        >
          <feather-icon icon="PlusIcon" size="18" />
        </b-button>
      </template>
      <template #cell(owner)="data">
        <span
          :id="data.item.id + '-owner'"
          title="Tooltip content"
          class="text-truncate"
          >{{ data.item.owner.substring(0, 20) + "..." }}</span
        >
        <b-tooltip
          triggers="hover"
          :target="`${data.item.id}-owner`"
          :title="data.item.owner"
          :delay="{ show: 200, hide: 50 }"
        />
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col />
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="filteredMedicines.length"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BTable,
  BButton,
  BFormInput,
  BPagination,
  BRow,
  BCol,
  BTooltip,
} from "bootstrap-vue";

import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { getUserData } from "@/auth/utils";
import MedicineListAdd from "./MedicineListAddNew";
import vSelect from "vue-select";
export default {
  components: {
    BModal,
    BTable,
    BButton,
    BFormInput,
    BPagination,
    BRow,
    BCol,
    BTooltip,
    vSelect,
    MedicineListAdd,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddPartnerSidebarActive",
    event: "update:is-add-partner-sidebar-active",
  },
  props: {
    isAddPartnerSidebarActive: {
      type: Boolean,
      required: true,
    },
    medicines: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      farmosetikOptions: [],
      ownerOptions: [],
      applicationOptions: [],
      required,
      filteredMedicines: [],
      formValidation: formValidation,
      searchQuery: "",
      owner: "",
      farmosetik: "",
      application: "",
      isAddNewMedicineSidebarActive: false,
    };
  },
  computed: {
    dataMeta() {
      return {
        to: this.perPage * this.currentPage,
        of: this.filteredMedicines.length,
      };
    },
  },
  watch: {
    searchQuery() {
      this.filteredMedicines = this.medicines.filter(
        (x) =>
          x.medicineName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          x.owner.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          x.farmosetik.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          x.application.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    owner() {
      if (!this.owner) this.owner = "";
      this.filteredMedicines = this.medicines.filter((x) =>
        x.owner.toLowerCase().includes(this.owner.toLowerCase())
      );
    },
    farmosetik() {
      if (!this.farmosetik) this.farmosetik = "";
      this.filteredMedicines = this.medicines.filter((x) =>
        x.farmosetik.toLowerCase().includes(this.farmosetik.toLowerCase())
      );
    },
    application() {
      if (!this.application) this.application = "";
      this.filteredMedicines = this.medicines.filter((x) =>
        x.application.toLowerCase().includes(this.application.toLowerCase())
      );
    },
  },
  async mounted() {
    this.resetblankmedicine();
    this.filteredMedicines = this.medicines;
    this.ownerOptions = Array.from(
      new Set(
        this.medicines.map(
          (x) => x.owner.substring(0, 1).toUpperCase() + x.owner.slice(1)
        )
      )
    );
    this.farmosetikOptions = Array.from(
      new Set(
        this.medicines.map(
          (x) =>
            x.farmosetik.substring(0, 1).toUpperCase() + x.farmosetik.slice(1)
        )
      )
    );
    this.applicationOptions = Array.from(
      new Set(
        this.medicines.map(
          (x) =>
            x.application.substring(0, 1).toUpperCase() + x.application.slice(1)
        )
      )
    );
  },
  methods: {
    async changed(val) {
      this.$emit("update:is-add-partner-sidebar-active", val);
      this.resetblankmedicine();
    },
    addPartner(medicine) {
      medicine.companyid = getUserData().companyid;
      medicine.plantid = this.$store.state.app.selectedPlantId;
      store.dispatch("definitionsModule/addMedicine", medicine).then((val) => {
        if (val != false) {
          this.$emit("refetch-data");
        }
      });
    },
    async resetblankmedicine() {
      this.blankMedicineData = {
        id: 0,
        medicinename: "",
        stokmiktari: 0,
        daystoeffectmilk: 0,
        birimfiyat: 0,
        companyid: getUserData().companyid,
        plantid: this.$store.state.app.selectedPlantId,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-medicine-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
