<template>
  <b-modal
    id="add-new-vaccine-sidebar"
    centered
    :visible="isAddNewVaccineSidebarActive"
    shadow
    size="lg"
    backdrop
    no-header
    :title="$t('vaccineVaccineAdd')"
    right
    ok-only
    :ok-title="$t('ok')"
    @ok="onSubmit(blankVaccineData)"
    @hidden="formValidation(resetblankvaccine).resetForm"
    @change="(val) => changed(val)"
  >
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblankvaccine
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 modal-form"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <button ref="submitButtonRef" type="submit" class="d-none" />
        <!-- vaccineName -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="vaccineName"
        >
          <b-form-group
            :label="$t('vaccineVaccineName')"
            label-for="vaccineName"
          >
            <b-form-input
              id="vaccineName"
              v-model="blankVaccineData.vaccinename"
              autofocus
              :state="
                formValidation(resetblankvaccine).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- daystoeffectmilk -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="daystoeffectmilk"
        >
          <b-form-group
            :label="$t('daystoeffectmilk')"
            label-for="daystoeffectmilk"
          >
            <b-form-input
              id="daystoeffectmilk"
              v-model="blankVaccineData.daystoeffectmilk"
              autofocus
              :state="
                formValidation(resetblankvaccine).getValidationState(
                  validationContext
                )
              "
              trim
              type="number"
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <!-- <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            {{ $t("add") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            {{ $t("cancel") }}
          </b-button>
        </div> -->
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewVaccineSidebarActive",
    event: "update:is-add-new-vaccine-sidebar-active",
  },
  props: {
    isAddNewVaccineSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankVaccineData: {
        id: 0,
        vaccinename: "",
        stokmiktari: 0,
        daystoeffectmilk: 0,
        birimfiyat: 0,
        companyid: getUserData().companyid,
        plantid: this.$store.state.app.selectedPlantId,
      },
      formValidation: formValidation,
    };
  },
  async mounted() {},
  methods: {
    async changed(val) {
      this.$emit("update:is-add-new-vaccine-sidebar-active", val);
      this.resetblankvaccine();
    },
    onSubmit(blankVaccineData) {
      store
        .dispatch("definitionsModule/addVaccine", blankVaccineData)
        .then((val) => {
          if (val != false) {
            this.$emit("refetch-data");
            this.$emit("update:is-add-new-vaccine-sidebar-active", false);
          }
        });
    },
    async resetblankvaccine() {
      this.blankVaccineData = {
        id: 0,
        vaccinename: "",
        stokmiktari: 0,
        daystoeffectmilk: 0,
        birimfiyat: 0,
        companyid: getUserData().companyid,
        plantid: this.$store.state.app.selectedPlantId,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-vaccine-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
