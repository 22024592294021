var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-new-vaccine-stock-sidebar","centered":"","visible":_vm.isEditVaccineStockSidebarActive,"shadow":"","size":"lg","backdrop":"","no-header":"","title":_vm.$t('vaccineVaccineStockEdit'),"right":"","ok-only":"","ok-title":_vm.$t('ok')},on:{"ok":function($event){return _vm.$refs.submitButtonRef.click()},"hidden":function($event){_vm.formValidation(_vm.resetblankvaccine).resetForm},"change":function (val) { return _vm.changed(val); }}},[_c('validation-observer',{ref:"formValidation(\n    resetblankvaccine\n  ).refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"refForm",staticClass:"p-2 modal-form",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit(_vm.blankVaccineData))},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('button',{ref:"submitButtonRef",staticClass:"d-none",attrs:{"type":"submit"}}),_c('validation-provider',{attrs:{"rules":"required","name":"priceperVaccine"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('priceperVaccine'),"label-for":"priceperVaccine"}},[_c('b-form-input',{attrs:{"id":"priceperVaccine","autofocus":"","state":_vm.formValidation(_vm.resetblankvaccine).getValidationState(
                validationContext
              ),"type":"number","trim":"","placeholder":""},model:{value:(_vm.blankVaccineData.priceperVaccine),callback:function ($$v) {_vm.$set(_vm.blankVaccineData, "priceperVaccine", $$v)},expression:"blankVaccineData.priceperVaccine"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"purchaseamount"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('purchaseamount'),"label-for":"purchaseamount"}},[_c('b-form-input',{attrs:{"id":"purchaseamount","autofocus":"","state":_vm.formValidation(_vm.resetblankvaccine).getValidationState(
                validationContext
              ),"trim":"","type":"number","placeholder":""},model:{value:(_vm.blankVaccineData.purchaseamount),callback:function ($$v) {_vm.$set(_vm.blankVaccineData, "purchaseamount", $$v)},expression:"blankVaccineData.purchaseamount"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"purchasedate"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('purchasedate'),"label-for":"purchasedate"}},[_c('b-form-datepicker',{attrs:{"id":"purchasedate","state":_vm.formValidation(_vm.resetblankvaccine).getValidationState(
                validationContext
              ),"locale":_vm.$i18n.locale,"placeholder":_vm.$t('purchasedate')},model:{value:(_vm.blankVaccineData.purchasedate),callback:function ($$v) {_vm.$set(_vm.blankVaccineData, "purchasedate", $$v)},expression:"blankVaccineData.purchasedate"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("totalPrice"))+" "+_vm._s(parseFloat( _vm.blankVaccineData.priceperVaccine * _vm.blankVaccineData.purchaseamount ).toFixed(_vm.getUserData().pricedecimal))+" "+_vm._s(_vm.getUserData().currency)+" ")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }