<template>
  <div>
    <vaccines-list-edit
      :key="'edit-' + JSON.stringify(selectedVaccine)"
      :is-edit-vaccine-sidebar-active.sync="isEditVaccineSidebarActive"
      :vaccine.sync="selectedVaccine"
      @refetch-data="fetchVaccines"
    />
    <vaccine-add-partner
      :key="vendorVaccines.length == 0 ? 0 : 1"
      :is-add-partner-sidebar-active.sync="isAddPartnerSidebarActive"
      :vaccines="vendorVaccines"
      @refetch-data="fetchVaccines"
    />
    <vaccine-stock
      :key="'stock-' + selectedVaccine.id"
      :vaccine="selectedVaccine"
      :purchases-function="fetchVaccinePurchases"
      :is-vaccine-stock-sidebar-active.sync="isVaccineStockSidebarActive"
      @refetch-data="fetchVaccines"
    />
    <!-- Table Container Card -->
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->

        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t("eachPage") }}</label>
          <v-select
            v-model="perPage"
            transition=""
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label> {{ $t("piece") }} </label>
        </b-col>
        <!-- Search -->
        <b-col cols="12" md="8">
          <div
            class="d-flex align-items-center justify-content-end flex-wrap mt-n2"
          >
            <div class="mr-1">
              <b-form-input
                v-model="searchQuery"
                class="mr-1 mt-1"
                :placeholder="$t('search')"
              />
            </div>
            <div>
              <b-button
                class="mt-1 mr-1"
                variant="primary"
                @click="isAddPartnerSidebarActive = !isAddPartnerSidebarActive"
              >
                <span class="text-nowrap">{{ $t("newVaccine") }}</span>
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refVaccineListTable"
      class="position-relative"
      :items="filteredVaccines"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('noRecords')"
      :sort-desc.sync="isSortDirDesc"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(delete)="data">
        <b-link
          v-if="data.item.allowDelete == 1"
          @click="deleteVaccine(data.item.id)"
        >
          <div class="d-flex">
            <feather-icon icon="TrashIcon" />
            <small class="align-middle ml-50 text-dark">{{
              $t("delete")
            }}</small>
          </div>
        </b-link>
      </template>

      <template #cell(add)="data">
        <b-link @click="openStock(data)">
          <div class="d-flex">
            <feather-icon icon="PlusIcon" />
            <small class="align-middle ml-50 text-dark">{{
              $t("stock")
            }}</small>
          </div>
        </b-link>
      </template>
      <template #cell(edit)="data">
        <b-link v-if="data.item.allowEdit == 1" @click="openEditSideBar(data)">
          <div class="d-flex">
            <feather-icon icon="EditIcon" />
            <small class="align-middle ml-50 text-dark">{{ $t("edit") }}</small>
          </div>
        </b-link>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
            {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
            {{ $t("entries") }} </span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="filteredVaccines.length"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useVaccineList from "../../useVaccineList";
import VaccinesListEdit from "./VaccineListEdit.vue";
import VaccineAddPartner from "./VaccineAddPartner.vue";
import VaccineStock from "./VaccineStock.vue";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    VaccinesListEdit,
    VaccineAddPartner,
    VaccineStock,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    vSelect,
  },
  data() {
    return {
      // typeOptions: [],
      // genderOptions: [
      //   { label: "Dişi", value: "Dişi" },
      //   { label: "Erkek", value: "Erkek" },
      // ],
    };
  },

  setup({ emit }) {
    const isEditVaccineSidebarActive = ref(false);
    const isAddPartnerSidebarActive = ref(false);
    const isVaccineStockSidebarActive = ref(false);
    const selectedVaccine = ref({});
    const userData = getUserData();
    const openEditSideBar = ({ item }) => {
      selectedVaccine.value = item;
      isEditVaccineSidebarActive.value = !isEditVaccineSidebarActive.value;
    };

    const openStock = async ({ item }) => {
      selectedVaccine.value = item;
      isVaccineStockSidebarActive.value = !isVaccineStockSidebarActive.value;
    };
    const {
      fetchVaccines,
      tableColumns,
      perPage,
      currentPage,
      totalVaccines,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refVaccineListTable,
      refetchData,
      filteredVaccines,
      allVaccine,
      vendorVaccines,
      fetchVendorVaccines,
      fetchVaccinePurchases,
      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteVaccine,
    } = useVaccineList();
    fetchVaccines();
    fetchVendorVaccines();
    return {
      // Sidebar
      isEditVaccineSidebarActive,
      isAddPartnerSidebarActive,
      isVaccineStockSidebarActive,
      openEditSideBar,
      openStock,
      allVaccine,
      vendorVaccines,
      fetchVaccinePurchases,

      selectedVaccine,
      filteredVaccines,
      fetchVaccines,
      tableColumns,
      perPage,
      currentPage,
      totalVaccines,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refVaccineListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI

      // Extra Filters
      typeFilter,
      genderFilter,
      deleteVaccine,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
