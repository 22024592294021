import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useMedicineList() {
  // Use toast
  const toast = useToast();

  const refMedicineListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "medicineName",
      label: VueI18n.t("medicineVaccineName"),
      sortable: false,
    },
    {
      key: "daystoeffectmilk",
      label: VueI18n.t("daystoeffectmilk"),
      sortable: false,
    },
    {
      key: "stokmiktari",
      label: VueI18n.t("stokmiktari"),
      sortable: false,
    },
    {
      key: "add",
      label: VueI18n.t("addPurchase"),
      sortable: false,
    },

    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit"), sortable: false },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allMedicine = ref([]);
  const filteredMedicines = ref([]);
  const vendorMedicines = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredMedicines.value.length;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to:
        perPage.value * (currentPage.value - 1) +
          perPage.value * currentPage.value <
        localItemsCount
          ? perPage.value
          : localItemsCount,
      of: filteredMedicines.value.length,
    };
  });

  const refetchData = () => {
    if (searchQuery.value)
      filteredMedicines.value = allMedicine.value.filter(
        (val) =>
          val.medicineName
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase()) || val.companyid != -1
      );
    else
      filteredMedicines.value = allMedicine.value.filter(
        (x) => x.companyid != -1
      );
  };
  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );
  const fetchMedicines = async (ctx, callback) => {
    var medicine = await store
      .dispatch(
        "definitionsModule/fetchMedicines",
        store.state.app.selectedPlantId
      )

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("medicines") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allMedicine.value = medicine;
    filteredMedicines.value = [...medicine].filter((x) => x.companyid != -1);
    return medicine;
  };

  const fetchVendorMedicines = async (ctx, callback) => {
    var medicine = await store
      .dispatch("definitionsModule/fetchVendorMedicines")
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("medicines") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    vendorMedicines.value = medicine || [];
    return medicine;
  };

  const fetchMedicinePurchases = async (ctx, callback) => {
    var medicine = await store
      .dispatch("definitionsModule/fetchMedicinePurchases", ctx)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("medicines") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    return medicine;
  };

  const deleteMedicine = (ctx) => {
    store
      .dispatch("definitionsModule/deleteMedicine", ctx)
      .then((response) => {
        fetchMedicines();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("medicine") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("medicine") }),
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchMedicines,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMedicineListTable,
    deleteMedicine,
    allMedicine,
    filteredMedicines,
    fetchVendorMedicines,
    vendorMedicines,
    refetchData,
    fetchMedicinePurchases,
    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
